<template>
	<div class="flex-row align-items-center">
		<CRow class="justify-content-center headimg mb-5">
			<CCol md="12" class="p-0 ">
				<div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
					<div class="middle-center text-center text-white">
						<h2 class=""><b>Laporan <span class="text-success">Realisasi</span></b></h2>
					</div>
				</div>
			</CCol>
		</CRow>
		<div class="container">
			<CRow class="justify-content-center">
				<CCol md="12"> 
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Data Realisasi {{kategori_permohonan}}</b></h4>
						</CCardHeader>
						<CCardBody>
							<table class="w-100 table-tr-spacing">
								
                                <tbody>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Nomor SK<span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            <input type="text" class="form-control" disabled="" v-model="prs_nomor_sk">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Tanggal Pengajuan<span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            <vuejs-datepicker :disabled="true" input-class="form-control" v-model="tgl_realisasi" format="dd MMM yyyy"></vuejs-datepicker> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Perihal <span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            <input type="text" class="form-control" disabled="" v-model="perihal_realisasi">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Kategori Permohonan <span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            <v-select :options="['Hortikultura','Sumber Daya Genetik', 'Perkebunan', 'Tanaman Pangan']" v-model="kategori_permohonan" placeholder="Kategori Permohonan" disabled=""></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Keterangan <span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            <textarea v-model="prs_keterangan" class="form-control"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 270px;"><label for=""><b>Data Benih <span class="text-danger">*</span></b></label></th>
                                        <td style="width: 15px;"> : </td>
                                        <td>
                                            Data Benih Untuk Realisasi
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table class="table table-bordered table-sm w-100" id="table-benih">
                                                <thead class="bg-sapphire">
                                                    <tr>
                                                        <th style="width: 15px">No</th>
                                                        <th v-if="kategori_permohonan != 'Sumber Daya Genetik'">Jenis Tanaman - Nama Latin</th>
                                                        <th>Nama Varietas</th>
                                                        <th class="d-none">Nama Varietas</th>
                                                        <th v-if="kategori_permohonan != 'Sumber Daya Genetik'">Bentuk Benih</th>
                                                        <th>Jumlah</th>
                                                        <th>Jumlah Realisasi</th>
                                                        <th>Jumlah sisa</th>
                                                        <th v-if="kategori_permohonan != 'Sumber Daya Genetik'">Satuan</th>
                                                        <th>Nilai (Total Harga dalam USD)</th>
                                                        <th v-if="kategori_permohonan != 'Sumber Daya Genetik'">Kode HS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(value_benih, index_benih) in data_benih" :key="index_benih">
                                                        <td>{{index_benih+1}}</td>
                                                        <td v-if="kategori_permohonan != 'Sumber Daya Genetik'">{{value_benih.tanaman.t_nama}} - {{value_benih.tanaman.t_nama_latin}}</td>
                                                        <td>{{value_benih.pb_varietas}}</td>
                                                        <td class="d-none">{{value_benih.pb_varietas}}</td>
                                                        <td v-if="kategori_permohonan != 'Sumber Daya Genetik'">{{value_benih.bentuk_benih.bb_nama}}</td>
                                                        <td>{{value_benih.prsb_jumlah_sisa}}</td>
                                                        <td>
                                                            <input type="number" class="form-control" v-model="data_benih_realisasi[index_benih].prsb_jumlah_realisasi" v-on:input="countJumlahSisaSistemBaru(index_benih)" />
                                                            <div :id="'alert-sisa-'+index_benih"></div>

                                                        </td>
                                                        <td><input type="number" class="form-control" v-model="data_benih_realisasi[index_benih].prsb_jumlah_sisa" disabled=""></td>
                                                        <td v-if="kategori_permohonan != 'Sumber Daya Genetik'">{{value_benih.satuan.s_nama}}</td>
                                                        <td>{{value_benih.pb_nilai_total}}</td>
                                                        <td v-if="kategori_permohonan != 'Sumber Daya Genetik'">{{value_benih.pb_kode_hs}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Upload Dokumen Realisasi
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="file"
                        class="form-control mb-0"
                        :id="'files-doc-realisasi'"
                        v-on:change="inputFileDocument"
                      />
                      <div
                        class="respond-input-file float-left"
                        :id="'docloading-realisasi'"
                      ></div>
                                        </td>
                                    </tr>
                                    <tr v-if="beforeChange">
                                        <th colspan="3">
                                            <div class="alert alert-info">
                                                Pilih nomor SK yang ingin di realisasikan.
                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-if="loadingProcess">
                                        <th colspan="3">
                                            <i class="fa fa-spin fa-refresh"></i> Loading...
                                        </th>
                                    </tr>
                                </tbody>
                            
							</table>
							
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<div class="btn btn-primary" v-if="is_simpan" v-on:click="submitProsesRealisasi()"><i class="fa fa-save"></i> Simpan Data Realisasi</div>
							</div>
						</CCardFooter>
					</CCard>
				</CCol>
			</CRow>
		</div>
	</div>
</template> 
<script>
const null_array = [];
	export default {
		name: "AddLaporanPemohonRealisasi", 
		components: {
			vuejsDatepicker
		},
		data() {
			return {
				list_no_sk: null_array,
				data_benih: null_array,
				data_benih_realisasi: null_array,
				sk_selected: "",
				id_permohonan_selected: "",
				tgl_realisasi: new Date(),
				session_data: JSON.parse(this.session),
				afterChange: false,
				beforeChange: false,
				loadingProcess: false,
				perihal_realisasi: "Laporan Realisasi",
				sistem_selected: "",
				permohonan_nomor_surat:"",
				list_tanaman: null_array,
				list_bentuk_benih: null_array,
				list_satuan: null_array,
				type_realisasi: '',
				prs_nomor_sk: '',
				list_negara: '',
				list_pelabuhan: '',
				negara: '',
				pelabuhan: '',
				prs_tanggal_sk: '',
				sistem_baru_loading: false,
				kategori_permohonan: '',
				prs_keterangan: '',
                idpermohonan: this.$route.params.idpermohonan,
      is_simpan: true,
      file_dokumen_realisasi: {
        dokumen_permohonan_id: "",
        nama_dpp: "Laporan Realisasi",
        file: "",
      },
			};
		},
		methods: {
			countJumlahSisa(keys){
				let realisasi = this.data_benih[keys].prsb_jumlah_realisasi;
				let jumlah = this.data_benih[keys].prsb_jumlah;

				this.data_benih[keys].prsb_jumlah_sisa = jumlah - realisasi;

			},
			countJumlahSisaSistemBaru(index_benih){
                // console.log(this.data_benih_realisasi[index_benih]);
				let realisasi = this.data_benih_realisasi[index_benih].prsb_jumlah_realisasi;
				let jumlah = this.data_benih_realisasi[index_benih].prsb_jumlah;
                let jumlah_sisa = jumlah - realisasi;
                if(jumlah_sisa < 0){
                    $('#alert-sisa-'+index_benih).html(`<span class="badge badge-danger"><i class="fa fa-times"></i> Jumlah Realisasi Lebih Banyak Dari Jumlah Pada SIP</span>`)
                    // this.is_simpan = false;
                }
                else{
                    $('#alert-sisa-'+index_benih).html(``)
                    
                    // this.is_simpan = true;
                }
                this.data_benih_realisasi[index_benih].prsb_jumlah_sisa = jumlah_sisa;

			},
			searchNegara(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["n_inisial", "n_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
		    searchPelabuhan(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["pl_code", "pl_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
			changeJenisTanaman(id_tanaman, key_row_tanaman){
                // var tanaman = this.list_tanaman[id_tanaman];
                var tanaman = this.list_tanaman.find(tanamans => {
                    if (tanamans.id === id_tanaman) {
                        return tanamans
                    }
                })
                // console.log(tanaman)
                this.data_benih[key_row_tanaman].prsb_nama_latin = tanaman.t_nama_latin;
            },
			submitProsesRealisasi(){
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				let type_realisasi = '';
				// checkRequired
				
				let required_form = new Promise(function(resolve, reject) {
					
                    $.each(self.data_benih_realisasi, function (key, value) { 
                        if(
                            value.prsb_jumlah_realisasi == '' || value.prsb_jumlah_realisasi == null
                        ){
                            Swal.fire({
                                icon: 'error',
                                title: 'Pastikan Data Benih Terisi',
                            });
                            resolve("stop");
                        }
                        if(
                            value.prsb_jumlah_sisa < 0
                        ){
                            console.log(value.prsb_jumlah_sisa)
                            Swal.fire({
                                icon: 'error',
                                title: 'Jumlah realisasi lebih dari jumlah pada SIP',
                                text: 'Cek kembali jumlah realisasi'
                            });
                            resolve("stop");
                        }
                    });
					resolve("next");
				});
				
				required_form.then(function (response) {
					if (response == 'stop') {
						return false;
					}
					var FormData = require('form-data');
					var data = new FormData();
					data.append('permohonan_id', self.id_permohonan_selected);
					data.append('permohonan_realisasi', JSON.stringify(self.data_benih_realisasi));
					data.append('permohonan_benih', JSON.stringify(self.data_benih));
					data.append('permohonan_nomor_surat', self.permohonan_nomor_surat);
					data.append('prs_nomor_sk', self.prs_nomor_sk);
					data.append('type', type_realisasi);
					data.append('prs_tanggal', "2021-05-05");
					data.append('prs_perihal', self.perihal_realisasi);
					data.append('prs_kategori', self.kategori_permohonan);
					
					data.append('prs_tanggal_sk', self.prs_tanggal_sk);
					data.append('negara_id', self.negara.id);
					data.append('pelabuhan_id', self.pelabuhan.id);
					data.append('prs_keterangan', self.prs_keterangan);
        data.append(
          "realisasi_dokumen",
          JSON.stringify(self.file_dokumen_realisasi)
        );
					var config = {
					method: 'post',
					url: self.apiLink + "api/permohonan_realisasi",
					headers: { 
						'Authorization': "Bearer " + self.access_token,
					},
					data : data
					};
					axios(config)
					.then(function (response) {
						var res_data = response.data;
						if (res_data.meta.code != "200") {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: res_data.data.message
							});
						}
						else{
							let timerInterval;
							Swal.fire({
								icon: "success",
								title: "Berhasil",
								text: "Anda akan diarahkan ke halaman list realisasi",
								timer: 2000,
								timerProgressBar: true,
								showCancelButton: false,
								showConfirmButton: false,
								didOpen: () => {
									timerInterval = setInterval(() => {
										const content = Swal.getContent();
										if (content) {
											const b = content.querySelector("b");
											if (b) {
												b.textContent = Swal.getTimerLeft();
											}
										}
									}, 100);
								},
								willClose: () => {
									clearInterval(timerInterval);
								},
							}).then((result) => {
								/* Read more about handling dismissals below */
								if (result.dismiss === Swal.DismissReason.timer) {
									// alert('ok');
                                    if(self.idpermohonan){

                                        self.$router.push({name: 'LaporanPemohonRealisasi', params: {idpermohonan: self.idpermohonan}});
                                    }
                                    else{
                                        self.$router.push({name: 'LaporanPemohonRealisasi'});
                                    }
								}
							});
						}
					}).catch((error) => {
						var error_res = error.response.data.meta;
						// console.log(error_res);
						if (error_res.code != 200) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: error_res.message
							});
						}
					});
				});
			},
			addRowBenih(){
                this.data_benih.push({
                    tanaman_id: '',
                    prsb_nama_umum: '-',
                    prsb_nama_latin: '',
                    prsb_varietas: '',
                    bentuk_benih_id: '',
                    prsb_jumlah: '',
                    prsb_jumlah_realisasi: '',
                    prsb_jumlah_sisa: '',
                    satuan_id: '',
                    prsb_nilai_total: '',
                    prsb_kode_hs: '',
                });
            },
			deleteRow(index, data_benih_at) {
	            var idx = this.data_benih.indexOf(data_benih_at);
	            if (idx > -1) {
	                this.data_benih.splice(idx, 1);
	            }
	        },
    inputFileDocument(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size < 2242880) {
          $("#docloading-realisasi").html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var permohonan_dokumen_in = this.file_dokumen_realisasi;
          var urlres = this.apiLink;
          var cleanUrl = this.normalizeUrl;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", file);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            permohonan_dokumen_in.file = response.data.data.file;
            // urlres += response.data.data.url;
            const newUrl = cleanUrl(urlres + response.data.data.url);
            $("#docloading-realisasi").html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                newUrl +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    normalizeUrl(url) {
      // Mencari semua instans dua slash atau lebih dan menggantinya dengan satu slash,
      // kecuali dua slash setelah protokol (http:, https:, file:, dan lain-lain).
      return url.replace(/([^:])\/{2,}/g, "$1/");
    },
		},
		mounted() {
			$('.no-readonly').prop('readonly', false);
		},
		created() {
			Swal.fire({
			    title: '<i class="fa fa-refresh fa-spin"></i>',
			    text: "Mohon tunggu sebentar...",
			    showConfirmButton: false,
			    allowOutsideClick: false,
			});
			// Get Master Negara
			// Get Master Tanaman
            axios.get(this.apiLink + "api/master/tanaman", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    var raw_tanaman = res_tanaman_data.master;
                    var clear_tanaman = [];
                    // var nama_module = this.module;
                    $.each(raw_tanaman, function(index_kategori, val_kategori) {
                        $.each(val_kategori.tanaman_kategori_sub, function(index_sub_kategori, val_sub_kategori) {
                            var sub_kategori = val_sub_kategori.tanaman_kategori_nama;
                            // if (sub_kategori.toLowerCase() == nama_module.toLowerCase()) {
                                $.each(val_sub_kategori.tanaman, function(index_tanaman, val_tanaman) {
                                    clear_tanaman[val_tanaman.id] = val_sub_kategori.tanaman[index_tanaman];
                                });
                            // }
                        });
                    });
                    var clear_tanaman = clear_tanaman.filter(function (el) {
                      return el != null;
                    });
                    this.list_tanaman = clear_tanaman;
                    
                }
            });
            axios.get(this.apiLink + "api/master/bentuk_benih", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_bentuk_benih = response.data;
                var res_bentuk_benih_data = res_bentuk_benih.data;
                if (res_bentuk_benih.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_bentuk_benih.data.message
                    });
                }
                else{
                    this.list_bentuk_benih = res_bentuk_benih_data.master;
                }
            });
            axios.get(this.apiLink + "api/master/satuan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_satuan = response.data;
                var res_satuan_data = res_satuan.data;
                if (res_satuan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_satuan.data.message
                    });
                }
                else{
                    this.list_satuan = res_satuan_data.master;
                }
            });
            // Get Master Negara
			axios.get(this.apiLink + "api/master/negara", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_negara = response.data;
				var res_negara_data = res_negara.data;
				if (res_negara.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_negara.data.message
					});
				}
				else{
					this.list_negara = res_negara_data.master;
				}
			});
			// Get Master Pelabuhan
			axios.get(this.apiLink + "api/master/pelabuhan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_pelabuhan = response.data;
				var res_pelabuhan_data = res_pelabuhan.data;
				if (res_pelabuhan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_pelabuhan.data.message
					});
				}
				else{
					this.list_pelabuhan = res_pelabuhan_data.master;
				}
			});
            // get detail permohonan untuk realisasi
            axios.get(this.apiLink + "api/permohonan_realisasi/permohonan", {
                params: {
                    nib: this.session_data.nib,
                    id: this.idpermohonan
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                this.sistem_baru_loading = false;
                var res_perohonan = response.data;
                var res_perohonan_data = res_perohonan.data;
                if (res_perohonan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_perohonan.data.message
                    });
                }
                else{
                    Swal.close();
                    var data_permohonan = res_perohonan_data.permohonan[0];
                    var raw_benih_realisasi = [];
                    var list_benih = [];
                    $.each(data_permohonan.permohonan_benih, function(index, val) {
                        var sisa = val.pb_jumlah_disetujui - val.jumlah_realisasi;
                        // if(val.prsb_jumlah_sisa == null){
                        //     var sisa =val.pb_jumlah_disetujui;
                        // }
                        raw_benih_realisasi[index] = {
                            "permohonan_benih_id" : val.id,
                            "prsb_jumlah": sisa,
                            "prsb_jumlah_realisasi": '',
                            "prsb_jumlah_sisa": '',
                        }
                        list_benih[index] = val;
                        list_benih[index].prsb_jumlah_sisa = sisa;
                    });
                    this.data_benih_realisasi = raw_benih_realisasi;
                    this.data_benih = list_benih;
                    this.id_permohonan_selected = data_permohonan.id;
                    this.prs_nomor_sk = data_permohonan.pr_nomor_sk;
                    this.kategori_permohonan = data_permohonan.pr_kategori;
          this.file_dokumen_realisasi.dokumen_permohonan_id =
            data_permohonan.permohonan_dokumen[0].dokumen_permohonan_id;
                }
            }).catch((error) => {
                if (error.response.data.data.error == 'Permohonan tidak ditemukan') {
                    this.sistem_baru_loading = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.data.error
                    });
                }
            });
            
		}
	};
</script>